<template>
  <div class="campaign">
    <div v-show="loading">
      <div class="loading-wrap">
        <div class="loading-spinner"></div>
      </div>
    </div>
    <div v-show="!loading">
      <div v-show="campaign.status == 'draft'">
        <div class="new-campaign">
          <div class="container">
            <div class="header-wrap">
              <h2>{{ $t("campaigns.create") }}</h2>
              <p class="lead">{{ $t("campaigns.createInfo") }}</p>
            </div>

            <div class="creation-wrap">
              <div v-if="creation.step == 2">
                <ul class="cf">
                  <li><span>1</span></li>
                  <li class="active"><span class="yellow">2</span>{{ $t("campaigns.step2") }}</li>
                  <li><span>3</span></li>
                </ul>

                <div>
                  <div class="creation-block">
                    <h3>{{ $t("campaigns.mediaTitle") }}</h3>
                    <p>{{ $t("campaigns.mediaInfo") }}</p>

                    <div v-if="advertisments_count > 0">
                      <div style="margin-top: 20px" class="caw">
                        <div class="row row-gutter-20">
                          <div class="col-8">
                            <div class="form-wrap">
                              <label for="search_phrase">{{ $t("campaigns.mediaSearch") }}</label>
                              <input
                                v-model="search_phrase"
                                v-on:input="search_handler"
                                class="form-input"
                                type="text"
                                name="search_phrase"
                                id="search_phrase"
                                :placeholder="$t('campaigns.mediaPlaceholder')"
                              />
                            </div>
                          </div>
                          <div class="col-16">
                            <div class="row row-gutter-20">
                              <div class="col-12">
                                <router-link
                                  :to="{ path: '/advertisments/new', query: { uuid: this.$route.params.uuid } }"
                                  class="button button-ci button-100 button-round"
                                  style="margin-top: 20px; font-size: 15px; padding-top: 16px; padding-bottom: 16px; letter-spacing: 0"
                                  ><span class="material-icons">backup</span
                                  ><span style="margin-left: 5px; display: inline-block; vertical-align: middle"> {{ $t("campaigns.mediaUpload") }}</span>
                                </router-link>
                              </div>
                              <div class="col-12">
                                
                                <a href="https://www.canva.com"
                                  class="button button-100 button-round desktop-only"
                                  style="background: #00c4cc; color: #fff; margin-top: 20px; font-size: 15px; padding-top: 16px; padding-bottom: 16px"
                                  target="_blank"
                                  v-if="this.$theme === 'oohlemon'">
                                  <img
                                    src="../../assets/images/canva.png"
                                    style="display: inline-block; vertical-align: middle; margin-right: 8px; width: 20px"
                                  /><span style="display: inline-block; vertical-align: middle">{{ $t("campaigns.mediaCreate") }}</span></a
                                >
                                <router-link
                                  :to="{ path: '/generator', query: { campaign_uuid: this.$route.params.uuid } }"
                                  class="button button-ci button-100 button-round"
                                  style="background: #444;color: #fff;margin-top: 20px; font-size: 15px; padding-top: 16px; padding-bottom: 16px; letter-spacing: 0"
                                  v-if="this.$theme === 'adnotam'">
                                  
                                  <span style="margin-left: 5px; display: inline-block; vertical-align: middle"> {{ $t("campaigns.mediaCreate") }}</span>
                                </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row row-gutter-20 adw">
                          <div v-for="ad in advertisments" v-bind:key="ad.uuid" class="col-4 ad-ca">
                            <input type="radio" :id="ad.uuid" :value="ad.uuid" v-model="advertisment_uuid" />
                            <label class="ca-wrap" :for="ad.uuid">
                              <div class="campaign-img">
                                <div v-if="ad.thumbnail">
                                  <img :src="ad.thumbnail" />
                                </div>
                                <div v-else>
                                  <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                                </div>
                              </div>
                              <div class="campaign-title">
                                <h3>{{ ad.name }}</h3>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="alert alert-info" style="margin-top: 20px; text-align: center">
                        {{ $t("campaigns.mediaEmpty") }}
                        <div class="row row-gutter-20">
                          <div class="col-12">
                            <router-link
                              :to="{ path: '/advertisments/new', query: { uuid: this.$route.params.uuid } }"
                              class="button button-ci button-100 button-round"
                              style="margin-top: 20px; font-size: 15px; padding-top: 16px; padding-bottom: 16px;letter-spacing: 0"
                              ><span class="material-icons">backup</span
                              ><span style="margin-left: 5px; display: inline-block; vertical-align: middle"> {{ $t("campaigns.mediaUpload") }}</span>
                            </router-link>
                          </div>
                          <div class="col-12">
                                
                                <a href="https://www.canva.com"
                                  class="button button-100 button-round desktop-only"
                                  style="background: #00c4cc; color: #fff; margin-top: 20px; font-size: 15px; padding-top: 16px; padding-bottom: 16px; letter-spacing: 0"
                                  target="_blank"
                                  v-if="this.$theme === 'oohlemon'">
                                  <img
                                    src="../../assets/images/canva.png"
                                    style="display: inline-block; vertical-align: middle; margin-right: 8px; width: 20px"
                                  /><span style="display: inline-block; vertical-align: middle">{{ $t("campaigns.mediaCreate") }}</span></a
                                >
                                <router-link
                                  :to="{ path: '/generator', query: { campaign_uuid: this.$route.params.uuid } }"
                                  class="button button-ci button-100 button-round"
                                  style="background: #444;color: #fff;margin-top: 47px; font-size: 15px; padding-top: 16px; padding-bottom: 16px; letter-spacing: 0"
                                  v-if="this.$theme === 'adnotam'">
                                  
                                  <span style="margin-left: 5px; display: inline-block; vertical-align: middle"> {{ $t("campaigns.mediaCreate") }}</span>
                                </router-link>
                              </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="creation-block">
                    <h3>Bildschirm oder Netzauswahl</h3>
                    <p>Du kannst entweder einzelne Bildschirme oder vordefinierte Netze auswählen.</p>

                    <ul class="nav nav-campaign cf">
                      <li class="active">
                        <a @click="networkType = 'devices'" :class="getNetworkClass('devices')">
                          <img src="/map.png">
                          <i>Bildschirme auswählen</i>
                        </a>
                      </li>
                      <li class="active">
                        <a @click="networkType = 'networks'" :class="getNetworkClass('networks')">
                          <img src="/map_network.png">
                          <i>Netze auswählen</i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div v-if="networkType == 'networks'" class="creation-block">
                    <h3>Netze auswählen</h3>
                    <p>Bitte wähle die gewünschten Netze aus auf denen deine Kampagne ausgestrahlt werden soll.</p>

                    <div class="networks-wrap">
                      <div class="form-wrap">
                        <label for="geocoder">Nach Netz oder Tags suchen...</label>
                        <input type="text" class="form-input">
                        <div class="tags-suggestions">
                          <span class="tags-title">Vorschläge:</span>
                          <span class="nw-tag">#nightlife</span>
                          <span class="nw-tag">#travel</span>
                          <span class="nw-tag">#sport</span>
                          <span class="nw-tag">#bw</span>
                          <span class="nw-tag">#stuttgart</span>
                          <span class="nw-tag">#bayern</span>
                        </div>
                      </div>

                      <div class="row row-gutter-20">
                        <div v-for="network in networks" v-bind:key="network.uuid" class="col-12">
                          <div class="network-wrap">
                            <h4>{{ network.title }}</h4>
                            <p class="desc" style="height:18px">{{ network.description }}</p>
                            <div class="nw-tags">
                              <span v-for="tag in network.title.split(' ')" v-bind:key="tag" class="nw-tag">#{{ tag }}</span>
                            </div>
                            <p style="margin-top:20px">
                              <a @click="select_network(network)" v-if="network.network_devices.length > 0" class="button button-yellow button-small button-100" style="padding: 8px 20px;font-size:14px">{{ network.network_devices.length }} Bildschirme auswählen ›</a>
                              <a v-else class="button button-grey button-small button-100" style="padding: 8px 20px;font-size:14px;cursor:no-drop">Netz hat keine Bildschirme</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="networkType == 'networks' && selected_network" class="creation-block">
                    <h3>Ausgewähltes Netz: {{ selected_network.title }}</h3>
                    <p style="margin-bottom:20px">Die folgenden Bildschirme befinden sich im ausgewählten Netzwerk.</p>

                    <MapView
                      :initialZoom="4.5"
                      :getPosition="false"
                      :adresssearch="false"
                      :showCircle="false"
                      :myMarker="false"
                      :clickRadius="false"
                      :editRadius="false"
                      :listView="true"
                      :selectedDevices.sync="selectedDevices"
                      :selectedNetworks.sync="selectedNetworks"
                      @locationChange="updateLocation"
                      @radiusChange="updateRadius"
                    ></MapView>

                  </div>

                  <div v-if="networkType == 'devices'" class="creation-block">
                    <h3>{{ $t("campaigns.locationTitle") }}</h3>
                    <p>{{ $t("campaigns.locationInfo") }}</p>
                    <br />
                    <MapView
                      :initialZoom="4.5"
                      :getPosition="true"
                      :adresssearch="true"
                      :showCircle="true"
                      :myMarker="true"
                      :clickRadius="true"
                      :editRadius="true"
                      :listView="true"
                      :selectedDevices.sync="selectedDevices"
                      :selectedNetworks.sync="selectedNetworks"
                      @locationChange="updateLocation"
                      @radiusChange="updateRadius"
                    ></MapView>
                  </div>
                    <h3>{{$t("campaigns.yourcampaign")}}</h3>
                    {{$t("campaigns.summaryDevices")}} <strong> {{ selectedDevices.length }}</strong>.<br />
                  <div style="font-size: 15px;">{{$t("campaigns.summaryDuration")}} <strong> {{campaign_time.toFixed(2)}} </strong> Stunden. <br />
                    {{$t("campaigns.summaryInfo")}}<br />
                    {{$t("campaigns.summaryduration")}}
                    <p>
                    {{$t("campaigns.summaryBudget")}}  <strong>{{(creation.budget * this.selectedDevices.length).toFixed(2)}} EUR/Tag.</strong> <br />
                    Das entspricht ca. <strong>{{(10 * this.campaign_time * this.selectedDevices.length * 30).toFixed(0)}}</strong> Ausspielungen deines Spots im Monat.
                    </p>
                  </div>
                  <div class="optional-features">
                    <div v-if="optional_features" class="optional-yes">
                      <h3>
                        <span class="material-icons">settings</span><span>{{ $t("campaigns.settings") }}</span>
                      </h3>
                      <p>{{ $t("campaigns.settingsInfo") }}</p>
                      <!-- uncomment frequency Block
                      <div class="creation-block">
                        <h3>{{ $t("campaigns.frequency") }}</h3>
                        <p>{{ $t("campaigns.frequencyInfo") }}</p>
                        <div class="frequency row row-gutter-20">
                          <div class="col-12">
                            <label for="high" class="custom-checkbox-conainer">
                              <h4>
                                {{ $t("campaigns.frequencyHigh") }}
                                <small>{{ $t("campaigns.frequencyHighInfo") }}</small>
                              </h4>
                              <input type="checkbox" id="high" value="high" v-model="creation.frequencies" />
                            </label>
                          </div>
                          <div class="col-8">
                            <label for="average">
                              <h4>Mittlere Frequenz<small>Werbung wird im ø von ca. 2 Personen gesehen.</small></h4>
                              <input type="checkbox" id="average" value="average" v-model="creation.frequencies">
                            </label>
                          </div>
                          <div class="col-12">
                            <label for="low" class="custom-checkbox-conainer">
                              <h4>
                                {{ $t("campaigns.frequencyLow") }}<small>{{ $t("campaigns.frequencyLowInfo") }}</small>
                              </h4>
                              <input type="checkbox" id="low" value="low" v-model="creation.frequencies" />
                            </label>
                          </div>
                        </div>
                      </div>
                      -->
                      <div class="creation-block" style="margin-bottom: 0">
                        <h3>{{ $t("campaigns.industries") }}</h3>
                        <p>{{ $t("campaigns.industriesInfo") }}</p>

                        <div class="tags-wrap cf">
                          <div class="row row-gutter-20">
                            <div v-for="industry in industries" v-bind:key="industry.id" class="col-12">
                              <div class="main-industries">
                                <div class="label custom-checkbox-conainer">
                                  <label :for="'checkbox-' + industry.id">{{ industry.name }}</label>
                                  <input
                                    type="checkbox"
                                    :id="'checkbox-' + industry.id"
                                    :value="industry.id"
                                    v-model="creation.industries"
                                    @change="mainIndustryCheckbox(industry.id)"
                                  />
                                  <span class="dropdown-arrow" @click="toggleDropdown(industry.id, $event)">
                                    <img src="../../assets/icons/dropdown.svg" />
                                  </span>
                                </div>
                              </div>

                              <div class="sub-industries" :id="'dropdown-' + industry.id">
                                <div v-for="subIndustry in industry.industries" v-bind:key="subIndustry.id" class="sub-industry">
                                  <div class="label custom-checkbox-conainer">
                                    <label :for="'checkbox-' + subIndustry.id">{{ subIndustry.name }}</label>
                                    <input type="checkbox" :id="'checkbox-' + subIndustry.id" :value="subIndustry.id" v-model="creation.industries" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p @click="enable_expert_mode" class="button button-grey button-100 button-small" style="margin: 0 0 20px 0">
                        <span class="material-icons">settings</span><span>{{ $t("campaigns.openSettings") }}</span>
                      </p>
                    </div>
                  </div>

                  <a @click="to_step_three" class="button button-ci button-100">{{ $t("campaigns.toStep3") }}</a>
                </div>
              </div>
              <div v-else>
                <ul class="cf">
                  <li><span>1</span></li>
                  <li><span>2</span></li>
                  <li class="active"><span class="yellow">3</span>{{ $t("campaigns.step3") }}</li>
                </ul>

                <div class="creation-block" style="margin-bottom: 0">
                  <h3>{{ $t("campaigns.budgetTitle") }}</h3>
                  <p>{{ $t("campaigns.budgetInfo") }}</p>

                  <div class="form-wrap">
                    <label>{{ $t("campaigns.budgetLabel") }}</label>
                    <input v-model="creation.budget" class="form-input" type="text" name="budget" id="budget" placeholder="200"/>
                  </div>

                  <div v-if="creation.budget" class="alert alert-details" style="font-size: 16px">
                    <p>
                      <strong>Das ist unserer Einsätzung nach das optimale Kampagnenbudget um die meisten Kontake zu erreichst.</strong>  <br />
                    </p>
                    <p>
                      Im nächsten Schritt kommst du zur deiner <strong> Kampagenübersicht </strong> hier kannst du nochmal alle Daten überprüfen und deine Kampagne bei uns einreichen.
                    </p>
                    <p>
                      Tagesbudget: <strong>{{ creation.budget }} EUR</strong><br />
                      Standard Spotlänge: 15 Sekunden<br />
                      Ausstrahlungen pro Tag: <strong> {{(creation.budget / 0.002 / 15).toFixed(0)}}</strong> <br />
                    </p>
                      Das wären dann <strong> {{(creation.budget / 0.002 / 15 * 30).toFixed(0) }}</strong> Ausstrahlungen im Monat. <br />
                  </div>

                  <a @click="finish_creation()" class="button button-ci button-100" style="white-space: nowrap">{{ $t("campaigns.createButton") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="campaign.status !== 'draft'">
        <div class="sub-wrap">
          <div class="container">
            <div class="single-campaign">
              <div class="campaign-header">
                <h2>{{ campaign.name }}</h2>
              </div>

              <div v-if="campaign.advertisment">
                <div v-if="campaign.status == 'pending'" class="content-wrap">
                  <header class="content-header cf">
                    <h3>{{ $t("campaigns.pending") }}</h3>
                  </header>
                  <div class="content-body">
                    <div class="alert alert-info">
                      <p style="margin: 0">
                        {{ $t("campaigns.pendingInfo1") }}<br />
                        {{ $t("campaigns.pendingInfo2") }} {{ campaign.budget_formatted }}
                        {{ $t("campaigns.pendingInfo3") }}
                      </p>
                    </div>
                    <p style="margin: 0; text-align: right">
                      <a @click="disable_campaign" class="button button-ci button-round" style="margin-top: 0">{{ $t("campaigns.pendingStop") }}</a>
                    </p>
                  </div>
                </div>
                <div v-else>
                  <div class="row row-gutter-20">
                    <div class="col-8">
                      <div class="content-wrap">
                        <header class="content-header">
                          <h3>{{ $t("global.details") }}</h3>
                          <div class="header-action badge-right">
                            <span v-if="campaign.status == 'draft'" class="badge">{{ $t("status.draft") }}</span>
                            <span v-if="campaign.status == 'creating'" class="badge">{{ $t("status.creating") }}</span>
                            <span v-if="campaign.status == 'created'" class="badge">{{ $t("status.created") }}</span>
                            <span v-if="campaign.status == 'pending'" class="badge">{{ $t("status.pending") }}</span>
                            <span v-if="campaign.status == 'running'" class="badge">{{ $t("status.running") }}</span>
                            <span v-if="campaign.status == 'stopped'" class="badge">{{ $t("status.stopped") }}</span>
                            <span v-if="campaign.status == 'paused'" class="badge">{{ $t("status.paused") }}</span>
                            <span v-if="campaign.status == 'blocked'" class="badge">{{ $t("status.blocked") }}</span>
                            <span v-if="campaign.status == 'deleted'" class="badge">{{ $t("status.deleted") }}</span>
                          </div>
                        </header>
                        <div class="content-body">
                          <div class="aside-wrap">
                            <div v-if="campaign.advertisment.thumbnail">
                              <img :src="campaign.advertisment.thumbnail" style="max-width: 100%" />
                            </div>
                            <div v-else>
                              <img :src="require('../../assets/themes/' + _self.$theme + '/placeholder.gif')" />
                            </div>
                            <div class="campaign-aside-wrap">
                              <h3>{{ campaign.name }}</h3>
                              <p>
                                <strong>{{ $t("global.duration") }}:</strong>
                                {{ campaign.advertisment.duration }} {{ $t("global.seconds") }}
                              </p>
                              <p>
                                <strong>{{ $t("global.adType") }}:</strong>
                                <span v-if="campaign.advertisment.advertisment_type == 'video'"> {{ $t("global.video") }} </span
                                ><span v-else>{{ $t("global.image") }}</span>
                              </p>
                              <p>
                                <strong>{{ $t("global.budget") }}:</strong>
                                {{ campaign.budget_formatted }}
                              </p>

                              <br />
                              <h3>{{ $t("global.period") }}</h3>
                              <p v-if="start_date_formatted">
                                <strong>{{ $t("campaigns.campaignStart") }}:</strong>
                                {{ start_date_formatted }}
                              </p>
                              <p v-if="end_date_formatted" style="margin-bottom: 10px">
                                <strong>{{ $t("campaigns.campaignEnd") }}:</strong> {{ end_date_formatted }}
                              </p>

                              <p v-if="created_date">
                                <strong>{{ $t("campaigns.campaignCreated") }}:</strong> {{ created_date }}
                              </p>
                              <p v-if="updated_date">
                                <strong>{{ $t("campaigns.campaignUpdated") }}:</strong>
                                {{ updated_date }}
                              </p>

                              <hr style="margin: 20px 0" />
                              <p v-if="campaign.status == 'running'" style="margin: 0 0 10px 0">
                                <a @click="stop_campaign" class="button button-grey button-100 button-round" style="margin-top: 0">
                                  {{ $t("campaigns.campaignStop") }}
                                </a>
                              </p>
                              <p v-if="campaign.status == 'paused'" style="margin: 0 0 10px 0">
                                <a @click="run_campaign" class="button button-grey button-100 button-round" style="margin-top: 0">
                                  {{ $t("campaigns.campaignStart") }}
                                </a>
                              </p>

                              <p v-if="campaign.status == 'paused' || campaign.status == 'stopped'" style="margin: 0">
                                <a @click="delete_campaign" class="button button-red button-small button-100 button-round" style="margin-top: 0">{{
                                  $t("campaigns.campaignDelete")
                                }}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="content-wrap">
                        <header class="content-header cf">
                          <h3>{{ $t("global.settings") }}</h3>
                        </header>
                        <div class="content-body">
                          <form @submit="update_campaign" class="form" method="post">
                            <div>
                              <div class="form-wrap">
                                <label for="name">{{ $t("global.name") }}</label>
                                <input v-model="campaign_update.name" class="form-input" type="text" name="name" id="name" />
                              </div>

                              <div class="form-wrap">
                                <label for="budget"
                                  >{{ $t("campaigns.budget") }}
                                  <em v-if="user.balance_formatted">({{ $t("global.balance") }}: {{ user.balance_formatted }})</em></label
                                >
                                <input v-model="campaign.budget" class="form-input" type="text" name="budget" id="budget" />
                              </div>
                            </div>

                            <p style="margin-bottom: 0; margin-top: 0; text-align: right">
                              <input type="submit" :value="$t('global.saveButton')" class="button button-ci button-round" />
                            </p>
                          </form>
                        </div>
                      </div>

                      <div class="content-wrap">
                        <header class="content-header cf">
                          <h3>
                            <span>{{ $t("campaigns.settings") }}</span>
                          </h3>
                        </header>
                        <div class="content-body">
                          <p style="margin-top: 0">{{ $t("campaigns.settingsInfo") }}</p>
                          <form @submit="update_campaign_timing" class="form" method="post">
                            <div class="creation-block">
                              <h3>{{ $t("campaigns.period") }}</h3>
                              <p>{{ $t("campaigns.periodInfo") }}</p>

                              <div class="form-wrap" style="margin: 8px 0 0 0">
                                <label for="start_date">{{ $t("campaigns.startDate") }}</label>
                                <input v-model="start_date" class="form-input" type="date" name="start_date" id="start_date" />
                              </div>
                              <div class="form-wrap" style="margin: 8px 0 24px">
                                <label for="end_date">{{ $t("campaigns.endDate") }}</label>
                                <input v-model="end_date" class="form-input" type="date" name="end_date" id="end_date" />
                              </div>
                            </div>

                            <div class="creation-block" style="margin-bottom: 0">
                              <h3>{{ $t("campaigns.periodTime") }}</h3>
                              <p>{{ $t("campaigns.periodTimeInfo") }}</p>
                              <div class="form-wrap" style="margin: 15px 0 0 0">
                                <label for="start_time">{{ $t("campaigns.periodStart") }}</label>
                                <input v-model="start_time" class="form-input" type="time" name="start_time" id="start_time" />
                              </div>
                              <div class="form-wrap" style="margin: 15px 0 0 0">
                                <label for="end_time">{{ $t("campaigns.periodEnd") }}</label>
                                <input v-model="end_time" class="form-input" type="time" name="end_time" id="end_time" />
                              </div>
                            </div>

                            <p style="margin-bottom: 0; margin-top: 24px; text-align: right">
                              <input type="submit" :value="$t('global.saveButton')" class="button button-ci button-round" />
                            </p>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="col-16">
                      <div v-if="campaign.status == 'created'" class="content-wrap">
                        <header class="content-header cf">
                          <h3>{{ $t("campaigns.campaignInactive") }}</h3>
                        </header>
                        <div class="content-body">
                          <div class="alert alert-info">
                            <p style="margin: 0">{{ $t("campaigns.campaignInactiveInfo") }}</p>
                          </div>
                          <!-- Kampagne einreichen erst möglich wenn Bezahlmethode hinterlegt -->
                          <div v-if="user.payment_methods">
                            <div v-if="user.payment_methods.length <= 0">
                              <div class="alert alert-danger cf">
                                <p style="margin: 0">
                                  {{ $t("global.addPaymentStart") }}
                                  <router-link to="/balance/setup_intents/new">{{ $t("global.addPaymentMethod") }}</router-link>
                                  {{ $t("global.addPaymentEnd") }}.
                                </p>
                              </div>
                            </div>
                            <div v-else>
                              <p style="margin: 0; text-align: right">
                                <a @click="enable_campaign" class="button button-ci button-round" style="margin-top: 0">{{ $t("campaigns.campaignCheck") }}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="campaign.status == 'running' || (campaign.status == 'paused' && campaign.impressions > 0)" class="content-wrap">
                        <header class="content-header cf">
                          <h3>
                            {{ $t("campaigns.funnel") }}
                          </h3>
                        </header>
                        <div class="content-body funnel">
                          <div>
                            <strong>{{ campaign.impressions }}</strong>
                            <span>{{ $t("global.showed") }}</span>
                          </div>
                          <div>
                            <strong>{{ campaign.contacts }}</strong>
                            <span>{{ $t("campaigns.impressions") }}</span>
                          </div>
                          <div>
                            <strong>Ø {{ (campaign.contacts / campaign.impressions).toFixed(2) }}</strong>
                            <span>{{ $t("campaigns.impressions") }} {{ $t("campaigns.per") }} {{ $t("global.show") }}</span>
                          </div>

                          <div v-if="campaign.qr_scan_count > 0">
                            <strong>{{ campaign.qr_scan_count }}</strong>
                            <span>{{ $t("campaigns.qrScans") }}</span>
                          </div>
                        </div>
                      </div>

                      <div v-if="campaign.status == 'running' || campaign.status == 'paused'" class="content-wrap">
                        <header class="content-header cf">
                          <h3>
                            {{ $t("campaigns.stats") }}
                            <span class="tooltip">
                              <span class="material-icons" style="color: #969696">info</span>
                              <md-tooltip :md-direction="direction">{{ $t("campaigns.statsInfo") }}</md-tooltip>
                            </span>
                          </h3>
                        </header>
                        <div class="content-body">
                          <div v-if="campaign.stats">
                            <div ref="chart" style="width: 100%; height: 300px"></div>
                            <div ref="chartcash" style="width: 100%; height: 300px"></div>
                          </div>
                          <div v-else>
                            <p>{{ $t("campaigns.statsEmpty") }}</p>
                          </div>
                        </div>
                      </div>

                      <div v-if="campaign.status == 'running' || campaign.status == 'paused'" class="content-wrap">
                        <header class="content-header cf">
                          <h3>{{ $t("campaigns.deliveries") }}</h3>
                        </header>
                        <div class="content-body table-container">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>{{ $t("global.stats") }}</th>
                                <th>{{ $t("campaigns.displayName") }}</th>
                                <th>{{ $t("global.location") }}</th>
                                <th>{{ $t("campaigns.delivered") }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="ad in ads" v-bind:key="ad.uuid">
                                <td width="20%">
                                  <span v-if="ad.status == 'created'">{{ $t("devices.adRequest") }}</span>
                                  <span v-if="ad.status == 'delivered'">{{ $t("devices.adServed") }}</span>
                                </td>
                                <td width="30%">
                                  <span v-if="ad.device" style="display: inline-block; vertical-align: middle; margin-right: 5px">{{ ad.device.name }}</span>
                                </td>
                                <td width="20%">
                                  <span v-if="ad.device">
                                    <a :href="'http://www.google.com/maps/place/' + ad.device.lat + ',' + ad.device.lng" target="_blank" style="color: #444">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        style="width: 14px; height: 14px; color: #444; display: inline-block; vertical-align: middle"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                      </svg>
                                      <span style="display: inline-block; vertical-align: middle; cursor: pointer; margin-left: 5px">{{
                                        $t("global.click")
                                      }}</span>
                                    </a>
                                  </span>
                                </td>
                                <td width="30%">
                                  {{ ad.updated_at_formatted }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="content-wrap">
                        <header class="content-header cf">
                          <h3>{{ $t("campaigns.location") }}</h3>
                        </header>
                        <div class="content-body">
                          <MapView
                            :initialZoom="14"
                            :showCircle="true"
                            :getPosition="false"
                            :campaignMarker="true"
                            :getCampaign="true"
                            :clickRadius="true"
                            :editRadius="true"
                            :listView="true"
                            :selectedDevices.sync="selectedDevices"
                            :selectedNetworks.sync="selectedNetworks"
                            style="width: 100%; height: 650px"
                            ref="finalMap"
                            @locationChange="updateLocation"
                            @radiusChange="updateRadius"
                          ></MapView>
                          <p style="margin-bottom: 0; margin-top: 20px; text-align: right">
                            <button @click="update_radius()" class="button button-ci button-round">
                              {{ $t("global.saveButton") }}
                            </button>
                          </p>
                        </div>
                      </div>

                      <div class="content-wrap">
                        <header class="content-header cf">
                          <h3>Coupons</h3>
                          <div v-if="coupons && coupons.length < 1" class="header-action">
                            <a @click="$modal.show('coupon-create')" class="button button-yellow button-round button-small">Coupon hinzufügen</a>
                          </div>
                        </header>
                        <div class="content-body">
                          <div v-if="coupons && coupons.length == 0" class="alert alert-danger" style="margin:0">
                            Noch kein Coupon für diese Kampagne vorhanden.
                          </div>
                          <div v-else>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th width="20%">Name</th>
                                  <th width="20%">Beschreibung</th>
                                  <th width="20%" style="text-align:center">Scans (CPC)</th>
                                  <th width="20%" style="text-align:center">Codes (CPL)</th>
                                  <th width="20%"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="coupon in coupons" v-bind:key="coupon.uuid">
                                  <td>{{ coupon.name }}</td>
                                  <td>{{ coupon.description }}</td>
                                  <td style="text-align:center">{{ coupon.scan_count }}</td>
                                  <td style="text-align:center">{{ coupon.claimed_codes_count }}/{{ coupon.codes_count }}</td>
                                  <td style="text-align: right">
                                    <a :href="base_url+'/r/'+coupon.rid" target="_blank" style="color:#444">
                                      <svg style="width: 22px;display:inline-block;vertical-align: middle;margin-left:8px" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                      </svg>
                                    </a>
                                    <svg @click="$modal.show('coupon-codes-create')" style="width: 22px;display:inline-block;vertical-align: middle;margin-left:8px;cursor:pointer" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <svg @click="$modal.show('coupon-codes-upload')" style="width: 22px;display:inline-block;vertical-align: middle;margin-left:8px;cursor:pointer" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                    </svg>
                                    <svg @click="deleteCoupon(coupon)" style="width: 22px;display:inline-block;vertical-align: middle;margin-left:8px;cursor:pointer" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <modal name="coupon-create" height="auto">
                        <div class="dialog-content">
                          <div class="dialog-c-title">Coupon erstellen</div>
                          <div class="dialog-c-text">
                            <div class="form-wrap">
                              <label for="name">Name</label>
                              <input v-model="coupon.name" type="text" class="form-input" />
                            </div>
                            <div class="form-wrap">
                              <label for="description">Beschreibung</label>
                              <textarea v-model="coupon.description" type="text" class="form-input" rows="2"></textarea>
                            </div>
                            <div class="form-wrap">
                              <label for="name">Coupon-Bild hochladen</label>
                              <input @change="couponImage" type="file" class="form-input" />
                            </div>
                          </div>
                          <div class="dialog-c-buttons">
                            <div class="row">
                              <div class="col-12">
                                <a @click="createCoupon" class="button button-yellow button-small button-100">Coupon erstellen ›</a>
                              </div>
                              <div class="col-12">
                                <a @click="$modal.hide('coupon-create')" class="button button-grey button-small button-100">Schließen</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </modal>

                      <modal name="coupon-codes-create" height="auto">
                        <div class="dialog-content">
                          <div class="dialog-c-title">Coupon Codes generieren</div>
                          <div class="dialog-c-text" style="min-height:inherit">
                            <div class="form-wrap">
                              <label for="name">Anzahl der zu generierenden Codes</label>
                              <input v-model="coupon_code.amount" type="text" class="form-input" />
                            </div>
                          </div>
                          <div class="dialog-c-buttons">
                            <div class="row">
                              <div class="col-12">
                                <a @click="createCouponCodes" class="button button-yellow button-small button-100">Coupon Codes erstellen ›</a>
                              </div>
                              <div class="col-12">
                                <a @click="$modal.hide('coupon-codes-create')" class="button button-grey button-small button-100">Schließen</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </modal>

                      <modal name="coupon-codes-upload" height="auto">
                        <div class="dialog-content">
                          <div class="dialog-c-title">Coupon Codes hochladen</div>
                          <div class="dialog-c-text" style="min-height:inherit">
                            <div class="form-wrap">
                              <label for="name">Bitte Ihre Codes in das Textfeld kopieren (pro Zeile ein Code)</label>
                              <textarea v-model="coupon_upload.coupon_codes" type="text" class="form-input" rows="6"></textarea>
                            </div>
                          </div>
                          <div class="dialog-c-buttons">
                            <div class="row">
                              <div class="col-12">
                                <a @click="uploadCouponCodes" class="button button-yellow button-small button-100">Coupon Codes hochladen ›</a>
                              </div>
                              <div class="col-12">
                                <a @click="$modal.hide('coupon-codes-upload')" class="button button-grey button-small button-100">Schließen</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </modal>

                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>{{ $t("campaigns.old") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { Plugins, CameraResultType } from "@capacitor/core";
const { Device } = Plugins;
const { Camera } = Plugins;
import echarts from "echarts";
import moment from "moment";
import MapView from "@/components/MapView";

export default {
  name: "campaigns",
  components: {
    MapView,
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_APP,
      devices: [],
      loading: true,
      fetched_location: false,
      search_phrase: "",
      advertisment_uuid: "",
      errors: [],
      campaign: {},
      advertisments: [],
      advertisments_count: -1,
      campaign_location: {
        loc: "Niederdorla",
        lat: 51.133333,
        lng: 10.416667,
        radius: 1000000,
      },
      current_location: {
        lat: 51.133333,
        lng: 10.416667,
      },
      optional_features: false,
      industries: [],
      industry: "",
      subIndustries: [],
      subIndustry: "",
      selected: "",
      creation: {
        step: 0,
        custom_budget: false,
        frequencies: ["high", "average", "low"],
        budget_type: "package",
        budget: 10,
        tags: [],
        industries: [],
      },
      campaign_update: {},
      mobile_asset: "",
      impressions: false,
      map: "",
      marker: "",
      radius_circle: "",
      chart_loading: true,
      echart_options: {
        color: ["#4DAA57", "#F3A712"],
        legend: {},
        tooltip: {},
        dataset: {
          source: [["date", "Einblendungen", "Impressionen"]],
        },
        xAxis: {
          type: "category",
        },
        yAxis: {},
        series: [{ type: "bar" }, { type: "bar" }],
      },
      echart_cash_options: {
        color: [this.$themeColor],
        legend: {},
        tooltip: {},
        dataset: {
          source: [["date", "Ausgaben"]],
        },
        xAxis: {
          type: "category",
        },
        yAxis: {},
        series: [{ type: "line" }],
      },
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      campaign_time: "",
      start_date_formatted: "",
      end_date_formatted: "",
      created_date: "",
      updated_date: "",
      user: {
        balance_formatted: "",
      },
      devices_geojson: {
        type: "FeatureCollection",
        features: [],
      },
      direction: "left",
      ads: [],
      selectedDevices: [],
      selectedNetworks: [],
      coupons: [],
      coupon: {},
      coupon_image: "",
      coupon_code: {
        amount: 100
      },
      coupon_upload: {
        coupon_codes: ""
      },
      networkType: null,
      networks: [],
      selected_network: null
    };
  },
  methods: {
    async get_device_info() {
      const info = await Device.getInfo();
      this.device = info;
      this.$store.commit("addDevice", info);
    },
    async get_ads() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/ads", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.ads = response.data.ads;
        });
    },
    async get_networks() {
      await axios
        .get(process.env.VUE_APP_BASE_API + "/networks", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.networks = response.data.networks;
        });
    },
    get_campaign() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/industries", {
          headers: { Authorization: store.getters.getToken },
        })
        .then((response) => {
          this.industries = response.data.industries;
          response.data.industries.forEach((industry) => {
            this.creation.industries.push(industry.id);

            // + subindustries
            industry.industries.forEach((subIndustry) => {
              this.creation.industries.push(subIndustry.id);
            });
          });
        });

      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.campaign = response.data.campaign;
          this.campaign_update = response.data.campaign;

          if (this.campaign.start_date) {
            this.start_date_formatted = moment(this.campaign.start_date).format("DD.MM.YYYY") + " - " + moment(this.campaign.start_time).format("HH:mm");

            this.start_date = moment(this.campaign.start_date).format("YYYY-MM-DD");
            this.start_time = moment(this.campaign.start_time).format("HH:mm");
          }

          if (this.campaign.end_date) {
            this.end_date_formatted = moment(this.campaign.end_date).format("DD.MM.YYYY") + " - " + moment(this.campaign.end_time).format("HH:mm");

            this.end_date = moment(this.campaign.end_date).format("YYYY-MM-DD");
            this.end_time = moment(this.campaign.end_time).format("HH:mm");
          }

          this.created_date = moment(this.campaign.created_at).format("DD.MM.YYYY - HH:mm");
          this.updated_date = moment(this.campaign.updated_at).format("DD.MM.YYYY - HH:mm");
          this.campaign_time = moment(this.campaign.end_time).diff(this.campaign.start_time) / 1000 /60 /60;
          this.campaign_date = moment(this.campaign.end_date).diff(this.campaign.start_date);

          //calculate campaign budget
          if (this.campaign_time <= 0 || this.campaign_time == 'NaN') {
            this.campaign_time = 24;
            this.creation.budget = 0.002 * 150 * this.campaign_time;
          }
            else{
            this.creation.budget = 0.002 * 150 * this.campaign_time;
          }

          if (this.campaign.status == "draft") {
            if (this.campaign.advertisment === null) {
              this.creation.step = 2;
            } else {
              //this.creation.budget =  parseFloat(this.creation.budget * this.selectedDevices.length).toFixed(2);     
              this.creation.step = 3;
            }
          }

          this.get_stats();
          this.loading = false;
          if (this.campaign.locations[0] && this.campaign_location) {
            this.campaign_location.lat = this.campaign.locations[0].lat;
            this.campaign_location.lng = this.campaign.locations[0].lng;
            this.campaign_location.radius = this.campaign.locations[0].radius;
          }
        });
    },
    get_stats() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/statistics", { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.impressions = response.data;
          setTimeout(() => {
            var that = this;
            Object.keys(response.data).forEach(function (key) {
              that.echart_options.dataset.source.push([response.data[key].date, response.data[key].impressions, response.data[key].contacts]);
              that.echart_cash_options.dataset.source.push([response.data[key].date, parseFloat(response.data[key].cash).toFixed(2)]);
            });
            this.chart_loading = false;
            setTimeout(() => {
              this.init_chart();
              this.init_chart_cash();
            }, 500);
          }, 500);
        });
    },
    get_coupons() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/coupons", { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          this.coupons = response.data.coupons;
        });
    },
    get_advertisments(page) {
      if (this.$route.query.select_advertisment_uuid) {
        this.advertisment_uuid = this.$route.query.select_advertisment_uuid;
      }
      axios
        .get(process.env.VUE_APP_BASE_API + "/advertisments?page=" + page + "&status=available&limit=12", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.loading = false;
          this.advertisments = response.data.advertisments;
          this.advertisments_count = this.advertisments.length;
        });
    },
    get_user() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/user", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.user = response.data.user;
        });
    },
    to_step_three() {
      if (this.selectedDevices.length > 0) {
        //this.creation.budget = this.creation.budget * this.selectedDevices.length;
        this.creation.budget =  parseFloat(this.creation.budget * this.selectedDevices.length).toFixed(2);
        //this.creation.spots = 10 * this.campaign_time * this.selectedDevices.length * 30;

        var locations = [
          {
            location_type: "coordinates",
            lat: this.campaign_location.lat,
            lng: this.campaign_location.lng,
            radius: this.campaign_location.radius,
          },
        ];
        axios
          .patch(
            process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
            {
              locations: locations,
              advertisment_uuid: this.advertisment_uuid,
              industries: this.creation.industries,
              frequencies: this.creation.frequencies,
              devices: this.selectedDevices,
              networks: this.selectedNetworks,
            },
            { headers: { Authorization: store.getters.getToken } }
          )
          .then((response) => {
            this.campaign = response.data.campaign;
            this.campaign_update = response.data.campaign;

            setTimeout(() => {
              this.creation.step = 3;
            }, 1000);
          })
          .catch((error) => {
            error.response.data.errors.forEach((value) => {
              this.$notify({
                group: "notification",
                type: "error",
                text: value,
              });
            });
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          text: "Kein Bildschirm ausgewählt",
        });
      }
    },
    finish_creation() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            budget_type: "daily",
            budget: this.creation.budget,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });

      setTimeout(() => {
        this.$refs.finalMap.rerenderMap();
      }, 1000);
    },

    casset_change(event) {
      this.mobile_asset = event.target.files[0];
    },
    async takePicture() {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      //var imageUrl = image.webPath;
      // Can be set to the src of an image now
      //imageElement.src = imageUrl;
      this.mobile_asset = image.base64String;
    },
    delete_campaign() {
      axios
        .delete(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid, { headers: { Authorization: store.getters.getToken } })
        .then((response) => {
          console.log(response);
          this.$router.push("/campaigns");
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },

    enable_expert_mode() {
      this.optional_features = true;
    },

    enable_campaign() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            status: "pending",
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    update_campaign: function (e) {
      e.preventDefault();

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            name: this.campaign.name,
            ad_duration: this.campaign.ad_duration,
            budget: this.campaign.budget,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    update_campaign_timing: function (e) {
      e.preventDefault();

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            end_date: this.end_date,
            end_time: this.end_time,
            start_date: this.start_date,
            start_time: this.start_time,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    update_radius: function () {
      var locations = [
        {
          location_type: "coordinates",
          lat: parseFloat(this.campaign_location.lat),
          lng: parseFloat(this.campaign_location.lng),
          radius: this.campaign_location.radius,
        },
      ];

      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            locations: locations,
            devices: this.selectedDevices,
            networks: this.selectedNetworks,
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.$notify({
            group: "notification",
            type: "success",
            text: this.$t("notification.saveSuccess"),
          });
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    disable_campaign() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            status: "created",
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    stop_campaign() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            status: "paused",
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    run_campaign() {
      axios
        .patch(
          process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid,
          {
            status: "running",
          },
          { headers: { Authorization: store.getters.getToken } }
        )
        .then((response) => {
          this.campaign = response.data.campaign;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    search_handler() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/advertisments?search_phrase=" + this.search_phrase + "&status=available", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.advertisments = response.data.advertisments;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    init_chart() {
      if (this.$refs.chart) {
        var chart = echarts.init(this.$refs.chart);
        chart.setOption(this.echart_options);
      }
    },
    init_chart_cash() {
      if (this.$refs.chartcash) {
        var chartcash = echarts.init(this.$refs.chartcash);
        chartcash.setOption(this.echart_cash_options);
      }
    },
    toggleDropdown(id, event) {
      document.querySelector("#dropdown-" + id).classList.toggle("active");
      event.target.classList.toggle("active");
    },
    mainIndustryCheckbox(id) {
      var mainChecked = document.querySelector("#checkbox-" + id).checked;
      var currentSubIndustries = this.industries.find((x) => x.id === id).industries;

      currentSubIndustries.forEach((subIndustry) => {
        var checkbox = document.querySelector("#checkbox-" + subIndustry.id);

        if (mainChecked === true) {
          checkbox.checked = true;
          if (!this.creation.industries.includes(subIndustry.id.toString())) {
            this.creation.industries.push(subIndustry.id.toString());
          }
        } else {
          checkbox.checked = false;
          if (this.creation.industries.includes(subIndustry.id.toString())) {
            var myIndex = this.creation.industries.findIndex((x) => x == parseInt(subIndustry.id));
            this.$delete(this.creation.industries, myIndex);
          }
        }
      });
    },
    updateLocation(value) {
      this.campaign_location.lng = value.lng;
      this.campaign_location.lat = value.lat;
    },
    updateRadius(value) {
      this.campaign_location.radius = value;
    },
    setInitTiming() {
      this.start_date = new Date().toISOString().substring(0, 10);
      var aYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      this.end_date = aYearFromNow.toISOString().substring(0, 10);
      this.start_time = "06:00";
      this.end_time = "22:00";
    },
    couponImage(event) {
      this.coupon_image = event.target.files[0];
    },
    createCoupon() {
      var form_data = new FormData();
      if (this.coupon && this.coupon.name) {
        form_data.append("name", this.coupon.name);
      }
      if (this.coupon && this.coupon.description) {
        form_data.append("description", this.coupon.description);
      }
      form_data.append("coupon_header", this.coupon_image);

      this.$http
        .post(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/coupons", form_data, {
          headers: { Authorization: this.$store.getters.getToken }
        })
        .then(() => {
          this.get_coupons();
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich erstellt"
          });
          this.coupon = {};
          this.$modal.hide('coupon-create')
        })
        .catch((error) => {
          error.body.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    deleteCoupon(coupon) {
      if (confirm("Wirklich löschen?")) {
        this.$http
        .delete(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/coupons/" + coupon.uuid, {
          headers: { Authorization: this.$store.getters.getToken }
        })
        .then(() => {
          this.get_coupons();
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich gelöscht"
          });
        })
        .catch((error) => {
          error.body.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
      }
    },
    createCouponCodes() {
      this.$http
        .post(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/coupons/" + this.coupons[0].uuid + "/create_codes", {
          amount: this.coupon_code.amount
        }, {
          headers: { Authorization: this.$store.getters.getToken }
        })
        .then(() => {
          this.get_coupons();
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich erstellt"
          });
          this.$modal.hide('coupon-codes-create')
        })
        .catch((error) => {
          error.body.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    uploadCouponCodes() {
      this.$http
        .post(process.env.VUE_APP_BASE_API + "/campaigns/" + this.$route.params.uuid + "/coupons/" + this.coupons[0].uuid + "/upload_codes", {
          coupon_codes: this.coupon_upload.coupon_codes
        }, {
          headers: { Authorization: this.$store.getters.getToken }
        })
        .then(() => {
          this.get_coupons();
          this.$notify({
            group: "notification",
            type: "success",
            text: "Erfolgreich erstellt"
          });
          this.$modal.hide('coupon-codes-upload')
        })
        .catch((error) => {
          error.body.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    },
    getNetworkClass(nwClass) {
      if (this.networkType == nwClass) {
        return 'active';
      } else {
        return '';
      }
    },
    select_network(network) {
      this.selectedDevices = [];
      network.devices.forEach(device => {
        this.selectedDevices.push(device.uuid)
      })
      this.selected_network = network;
    }
  },
  mounted() {
    this.get_campaign();
    this.get_user();
    this.get_device_info();
    this.get_advertisments(1);
    this.get_ads();
    this.setInitTiming();
    this.get_coupons();
    this.get_networks();

    if (window.innerWidth < 500) {
      this.resp_mode = true;
      this.toggled_ad_pipe = false;
      this.direction = "bottom";
    } else {
      this.resp_mode = false;
      this.direction = "right";
    }
  },
  /*
  watch: {
    selectedDevices: {
      handler(val, oldVal) {
        console.log(this.selectedDevices);
        console.log(val + " " + oldVal);
      },
    },
  },
  */
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.campaign-header {
  margin-bottom: 30px;

  h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
  }
}

.aside-wrap {
  padding: 20px;

  h3 {
    font-weight: 600;
  }

  p {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.new-campaign .creation-wrap .creation-block .campaign-plans .plan {
  border: 2px solid #f8f8f8;
}

.new-campaign .creation-wrap .creation-block .campaign-plans input {
  display: none;
}

.new-campaign .creation-wrap .creation-block .campaign-plans input[type="radio"]:checked + label {
  background: #fff;
  border: 2px solid var(--ci-color);
}

@media only screen and (max-width: 450px) {
  .single-campaign {
    .col-8 {
      width: 100%;
    }

    .col-16 {
      width: 100%;
    }
  }
}

.mapboxgl-ctrl-group button {
  width: 50px;
  height: 50px;
  background-size: 30px 30px;
}

.campaign-aside-wrap {
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
  margin-top: 20px;

  h3 {
    font-weight: 500;
    font-size: 20px !important;
    margin-bottom: 15px !important;

    .badge {
      font-size: 12px;
      margin-right: 10px;
      font-weight: 400;
      background-color: rgba(24, 144, 255, 0.1);
      color: #1890ff;
      border: 1px solid rgba(24, 144, 255, 0.2);
      padding: 4px 8px;
      border-radius: 3px;
    }
  }

  strong {
    font-weight: 600;
  }
}

.map-wrap {
  position: relative;

  #map {
    width: 100%;
    height: 500px;
  }

  .map-meta {
    width: 100%;
    background: #f9f9f9;
    padding: 20px;

    @media (min-width: 768px) {
      display: flex;
      align-items: baseline;

      .form-wrap {
        width: 100%;
        margin-top: 0 !important;
        margin-bottom: 10px !important;

        &:nth-of-type(2) {
          margin-left: 20px;
        }
      }
    }
  }
}

.tags-wrap {
  margin-top: 20px;

  .label {
    display: inline-block;
    width: 100%;
    position: relative;
    background: #fff;
    padding: 20px 40px 20px 60px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;

    &:hover {
      cursor: default;
    }

    label:hover,
    input:hover {
      cursor: pointer;
    }

    label {
      font-size: 17px;
      font-weight: 600;
      margin: 0;
    }

    input {
      position: absolute;
      left: 23px;
      top: 23px;
    }
  }
}

.ad-ca {
  position: relative;

  input {
    position: absolute;
    bottom: 15px;
    left: 20px;
  }
}

.sub-industries {
  overflow: hidden;
  max-height: 0;

  &.active {
    max-height: 1000px;
  }
}

.sub-industry {
  padding-left: 30px;
}

.sub-industry .label {
  background: #efefef;
}

.main-industries {
  .label {
    position: relative;

    h4 {
      margin-right: 20px;
    }

    .dropdown-arrow {
      transform: rotate(0) translate(-50%, -50%);
      position: absolute;
      top: 50%;
      right: 5px;

      img.active {
        transform: rotate(180deg);
      }

      &.active {
        transform: rotate(270deg) translate(70%, 40%);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.mapboxgl-popup-content {
  padding: 0;

  button {
    background: white;
    color: black;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    font-size: 20px;
    top: 5px;
    right: 5px;
    line-height: 1;
    display: flex;
    align-items: baseline;
    justify-content: center;
    line-height: 1.5;

    &:hover {
      background: lightgray;
    }
  }

  p {
    font-weight: 600;
    margin-top: 0 !important;
    padding: 10px;
    margin-top: 0;
  }

  img {
    max-height: 250px;
  }
}

#geocoder {
  width: 100%;

  .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: 100%;

    input {
      display: block;
      width: 100%;
      padding: 14px;
      line-height: 1.5;
      background: #fff;
      border: 1px solid #d7e3e6;
      -webkit-box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
      box-shadow: inset 0 0 3px 0 rgba(215, 227, 230, 0.4);
      border-radius: 4px;
      font-size: 16px;
    }

    .geocoder-pin-right {
      display: none;
    }
  }

  .suggestions li {
    padding-right: 0 !important;
    width: 100%;

    &::after {
      display: none !important;
    }
  }
}

@media (min-width: 1024px) {
  .fifthy {
    display: flex;
    > div {
      width: 50%;

      &:nth-of-type(1) {
        margin-right: 10px;
      }
    }
  }
}

.badge-right span {
  margin-right: 0;
}

.funnel {
  position: relative;
  overflow: hidden;
  max-width: 600px;
  margin: 0 auto;

  div {
    text-align: center;
    padding: 15px 0;

    strong,
    span {
      display: block;
    }

    strong {
      font-size: 1.5rem;
      font-weight: 600;

      @media (min-width: 768px) {
        font-size: 2rem;
      }
    }

    span {
      font-size: 0.75rem;
      color: #707070;

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }

    &:nth-of-type(even) {
      background: #fafafa;
    }

    &:nth-of-type(odd) {
      background: #f4f4f4;
    }
  }

  &:before,
  &:after {
    border-color: #fff;
    border-style: solid;
    border-top-color: transparent;
    box-sizing: border-box;
    content: "";
    position: absolute;
    top: 0;
  }

  &:before {
    border-width: 0 8rem 30rem 0;
    border-right-color: transparent;
    left: 15px;
  }

  &:after {
    border-width: 0 0 30rem 8rem;
    border-left-color: transparent;
    right: 15px;
  }
}

.nav-campaign {
  width: 100%;
  margin-top: 20px !important;

  li {
    width: 50%;
    padding-right: 20px !important;

    a {
      display: block;
      border: 1px solid #f1f1f1;
      text-align: center;
      padding: 20px 40px;

      img {
        width: 30px;
        display: inline-block;
        margin-bottom: 10px;
      }

      i {
        display: block;
        font-style: normal;
        font-size: 14px;
      }
    }

    a:hover {
      border: 1px solid #dcdcdc;
    }

    a.active {
      background: #f9f9f9;
      border: 1px solid #dcdcdc;
    }
  }

  li::after {
    display: none !important;
  }
}

.networks-wrap {
  margin-top: 20px;
  width: 100%;
  background: #f9f9f9;
  padding: 20px;

  .form-wrap {
    margin: 0 0 30px 0!important;

    .tags-suggestions {
      margin-top: 10px;

      span.tags-title {
        background: #f9f9f9;
        border: none;
        padding: 5px 0 5px 5px;
        border-radius: 3px;
        font-size: 12px;
        margin-right: 10px;
      }

      span {
        border: 1px solid #dcdcdc;
        background: #fff;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }

  .network-wrap {
    background: #fff;
    border: 1px solid #dcdcdc;
    padding: 20px;
    margin-bottom: 20px;
    min-height: 172px;

    h4 {
      margin: 0;
      font-size: 16px;
    }

    p.desc {
      margin: 0;
    }

    p.meta {
      margin: 0;
      font-size: 12px;
    }

    .nw-tags {
      margin-top: 15px;

      span {
        border: 1px solid #dcdcdc;
        background: #f9f9f9;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
}
</style>
